import {
  countries,
  CountryCode,
  CountrySubsetConfiguration,
  Locale,
  PhoneNumberLengthValidation,
  ZipCodeValidation,
} from "../settings/countries";
import { PageId } from "../settings/pages";
import { experiments, isExperimentDefaultVariant } from "./experiments";

export const isIdDocumentRequired = (country: CountryCode): boolean => {
  return countries[country].idDocumentRequired;
};

export const getLocaleByCountry = (country?: CountryCode): Locale | string => {
  if (!country) {
    return "es";
  }

  return countries[country].defaultLocale;
};

export const getCountryByLocale = (locale?: Locale): CountryCode => {
  if (!locale || !Object.values(CountryCode).includes(locale.toUpperCase() as CountryCode)) {
    return CountryCode.ES;
  }

  return locale.toUpperCase() as CountryCode;
};

export const homePath = (country?: CountryCode): string => {
  if (!country) {
    return "/es/";
  }

  return `/${country.toLowerCase()}`;
};

export const shouldListTaxes = (country: CountryCode): boolean => {
  return countries[country].listTaxes;
};

export const shouldShowSelectableProductsDisclaimer = (country: CountryCode): boolean => {
  return countries[country].selectableProductsDisclaimer;
};

export const getPhoneNumberLength = (country: CountryCode): PhoneNumberLengthValidation => {
  return countries[country].phoneNumberLength;
};

export const getPhoneNumberPrefix = (country: CountryCode): string => {
  return countries[country].phoneNumberPrefix;
};

export const shouldShowContactsLinks = (country: CountryCode): boolean => {
  return countries[country].contactLinks;
};

export const getMultiplePetsDiscount = (country: CountryCode): number => {
  return countries[country].multiplePetsDiscount;
};

export const shouldShowHasTattooAnswer = (country: CountryCode): boolean => {
  return countries[country].hasTattooAnswer;
};

export const shouldShowComplianceErrorMessage = (country: CountryCode): boolean => {
  return countries[country].hasComplianceErrorMessage;
};

export const shouldNavigateToSmallPrintPages = (country: CountryCode): boolean => {
  return countries[country].smallPrintPages;
};

export const shouldShowEuFederLogos = (country: string): boolean => {
  if (!country) {
    return false;
  }

  return countries[country as CountryCode].euFederLogos;
};

export const getZipCodeLength = (country: CountryCode): ZipCodeValidation => {
  return countries[country].zipCode;
};

export const shouldShowAlternativeOnboardingEmailTitle = (country: CountryCode): boolean => {
  return countries[country].alternativeOnboardingEmailTitle;
};

export const shouldAllowBecasDiscount = (country: CountryCode): boolean => {
  return countries[country].becasDiscount;
};

export const getCountriesSubset = (): CountrySubsetConfiguration[] =>
  Object.values(countries).map(({ name, countryCode, defaultLocale }) => ({
    name,
    countryCode,
    defaultLocale,
  }));

export const isPolicyStartDateChangeAvailable = (country: CountryCode): boolean =>
  countries[country].allowChangePolicyStartDate;

export const shouldAllowFakeChipNumber = (country: CountryCode): boolean =>
  countries[country].fakeChipNumber;

export const shouldShowChatLink = (country: CountryCode, pageId: PageId): boolean =>
  countries[country].chatLink && countries[country].pagesWithChatLink.includes(pageId);

export const shouldNotifyPreContract = (country: CountryCode): boolean => {
  return countries[country].preContractNotificationIsRequired;
};

export const shouldShowDescriptionInAccessPage = (country: CountryCode): boolean =>
  countries[country].hasDescriptionInAccessPage;

export const shouldAllowCustomCheckoutSession = (): boolean =>
  process.env.GATSBY_CUSTOM_CHECKOUT_PAGE === "true";

export const getPaymentMethodsAvailables = (country: CountryCode): string[] =>
  countries[country].paymentMethods;

export const shouldShowGlAddon = (country: CountryCode): boolean => countries[country].glAddon;

export const shouldShowPhoneNumberInPreQuotes = (country: CountryCode): boolean =>
  (countries[country].phoneNumberInPreQuotes &&
    isExperimentDefaultVariant(experiments.phoneNumberInPreQuotes)) ||
  (country === CountryCode.ES &&
    shouldAskForPhoneNumberBeforePetParentPage(country) &&
    isExperimentDefaultVariant(experiments.askForPhoneNumberBeforePetParentPage));

export const shouldAskForPhoneNumberBeforePetParentPage = (country: CountryCode): boolean =>
  countries[country].phoneNumberBeforePetParentPage;

export const shouldShowScheduleCallOption = (country: CountryCode): boolean =>
  countries[country].scheduleCallOption;

export const shouldShowWhatsAppConfirmationCheckbox = (country: CountryCode): boolean =>
  countries[country].hasWhatsAppConfirmationCheckbox;

export const shouldSanitizePhoneNumber = (country: CountryCode): boolean =>
  countries[country].sanitizePhoneNumber;
